<template>
    
    <!------------------- Sort ------------------------>
    <div v-if="routeName != 'home' && tmpItems.length>0" class="col-12 row">
        <div class="col-3 offset-sm-6 col-sm-2">
            <!-- <q-select v-if="state.device=='xbox'"
                v-model="filterFlag" 
                :options="filFlag" 
                :color="state.color"
                option-value="value"
                option-label="label"
                label="Country" 
                emit-value
                map-options
                behavior="menu"
                @update:model-value="changeItem()">
                <template v-slot:selected-item="scope">
					<div class="ellipsis">{{scope.opt.label}}</div>
				</template>
            </q-select> -->
            <q-select v-if="state.device=='nintendo' || state.device=='xbox'"
                v-model="filterFlag" 
                :options="filFlagNin" 
                :color="state.color"
                label="Country" 
                emit-value
                map-options
                options-dense
                behavior="menu"
                @update:model-value="changeItem()">
                <template v-slot:selected-item="scope">
					<div class="ellipsis">{{scope.opt}}</div>
				</template>
            </q-select>
        </div>
        <div class="q-px-md col-4 col-sm-2">
            <q-select v-if="state.device!='appletv' && state.device!='home'" v-model="filterPrice" :options="filPrice" 
                :color="state.color"
                option-value="value"
                option-label="label"
                label="Price" 
                emit-value
                map-options
                behavior="menu"
                @update:model-value="changeItem()">
                <template v-slot:selected-item="scope">
					<div class="ellipsis">{{scope.opt.label}}</div>
				</template>
            </q-select>
        </div>
        <div class="col-5 col-sm-2">
            <q-select v-model="order" :options="orders" 
                v-if="state.device!='home'"
                :color="state.color"
                option-value="value"
                option-label="label"
                label="Sort" 
                emit-value
                map-options
                behavior="menu"
                @update:model-value="changeOrder()">
                <template v-slot:selected-item="scope">
					<div class="ellipsis">{{scope.opt.label}}</div>
				</template>
            </q-select>
        </div>
    </div>

    <div v-if="state.device=='home' && !state.showHot && tmpItems.length>0" class="col-12 row">
        <div class="col-3 col-sm-2">
            <q-select v-model="filterDevice" :options="filDevice" 
                :color="state.color"
                option-value="value"
                option-label="label"
                label="Device" 
                emit-value
                map-options
                behavior="menu"
                @update:model-value="changeItem()">
                <template v-slot:selected-item="scope">
					<div class="ellipsis">{{scope.opt.label}}</div>
				</template>
            </q-select>
        </div>
    </div>

    <!------------------- Total ------------------------>
    <div v-if="routeName == 'library' && tmpItems.length>0" class="col-12">
        <div>
            Total : <span :style="'color:'+state.color" ><b>฿{{total}}</b></span>
            <q-btn dense flat label="export" icon="mdi-microsoft-excel" :color="state.color" size="sm" class="q-ml-md" @click="exportExcel()"></q-btn>
        </div>
    </div>

    <!------------------- Pagination ------------------------>
    <div ref="begin" v-if="totalPage>1" class="col-12 q-pt-md flex flex-center">
        <q-pagination
            v-model="page"
            :max="totalPage"
            :max-pages="7"
            padding="1px 9px"
            direction-links
            :color="state.color"
        />
    </div>

    <!------------------- Show View Card ------------------------>
    <template v-if="!loading && !state.view">
        <div v-for="item in vItems" :key="item" class="col-xs-6 col-sm-3 col-md-2" :style="cardWidth">
            
            <!------------------- Playstation ------------------------>
            <q-card v-if="state.device=='playstation' || item.sid" :class="bgColor(item)+' column my-card'" :style="imageHeight(item)">
                <div style="position: absolute;right:6px">
                    <span style="color:#EEB400" v-if="item.essentials==1"><b>Essentials</b></span>
                    <span style="color:#EEB400" v-if="item.plus"><b>{{item.plus}}</b></span>
                </div>
                <span class="q-focus-helper"></span>
                <div v-if="item.platform.indexOf('PS4')>=0" style="background-color:#2E86C1">
                    <q-img
                        src="/images/ps4.png"
                        loading="lazy"
                        class="q-ma-xs"
                        width="40%"
                    >
                    </q-img>
                </div>
                <div v-else style="background-color:white" >
                    <q-img
                        src="/images/ps5.png"
                        loading="lazy"
                        class="q-ma-xs"
                        width="40%"
                    >
                    </q-img>
                </div>
                <q-img
                    :src="posterURL(item)"
                    loading="lazy"
                    spinner-color="white"
                    class="col cursor-pointer q-hoverable"
                    @click="detail(item)"
                    :fit="state.device=='home'?'contain':''"
                >
                </q-img>
                <q-card-actions vertical class="q-pa-xs" align="around">
                    <div class="text-subtitle2" v-if="store=='th'">
                        <span v-if="item.sthai == 1" style="color:red">TH</span>
                        <span v-if="item.detail=='Game'"> EN</span>
                        {{item.detail.replace('English','EN').replace('Chinese','ZH').replace('Korean','KO').replace('Japanese','JP').replace('Game','&nbsp;').replace('Ver.','').replace(/\//g," ")}}
                    </div>
                    <div class="q-pa-none flex flex-center">
                        <q-btn flat round dense size="sm" icon="mdi-sony-playstation" @click="open('https://store.playstation.com/'+storeURL+'/product/'+item.sid)"></q-btn>
                        <!-- <q-btn flat round dense size="sm" icon="photo_camera" @click="copyPic('detail')"></q-btn> -->
                        <q-btn v-if="state.user.username!='' && item.own==null" flat round dense size="sm" icon="star_border" :loading="loadMy" @click="addLibrary(item.sid)" />
                        <q-btn v-if="state.user.username!='' && item.own=='y'" flat round dense size="sm" icon="star" color="orange" :loading="loadMy" @click="delLibrary(item.sid,item.price_thai)" />
                    </div>
                    <div class="text-subtitle2">
                        <span :style="'font-size:12px;color:'+fontColor(item.priceMode)" class="q-px-sm">
                            <i v-if="item.priceMode!=''">-{{item.pricePercent}}%&nbsp;</i>
                            <span style="font-size:16px;">{{item.price_thai != 0 ? '฿'+item.price_thai : 'FREE' }}</span>
                        </span>
                    </div>
                </q-card-actions>
            </q-card>

            <!------------------- Xbox ------------------------>
            <q-card v-else-if="state.device=='xbox' || item.xid" :class="bgColor(item)+' my-card'" :style="imageHeight(item)">
                <div v-if="state.device=='home'" style="background-color:green">
                    <q-img
                        src="/images/xbox.png"
                        loading="lazy"
                        class="q-ma-xs"
                        height="15px"
                        fit="contain"
                    >
                    </q-img>
                </div>
                <q-img
                    :src="'https://image.numcreator.com/xbox/'+item.xid+'.png'"
                    loading="lazy"
                    spinner-color="white"
                    class="cursor-pointer q-hoverable"
                    @click="detail(item)"
                >
                    <q-img v-if="item.xs==1 || item.available=='XboxSeriesX'"
                        src="/images/xs.png"
                        loading="lazy"
                        class="q-ma-none absolute-bottom-left"
                        fit="fill"
                        width="60px"
                        height="10px"
                        style="opacity:0.9"
                    />
                    <span v-if="item.thai!=null" class="q-pl-sm absolute-bottom text-center" style="font-size:14px;color:black;opacity:0.6">
                        <q-chip dense><b>TH</b></q-chip>
                    </span>
                    <q-img v-if="item.gp==1"
                        src="/images/gp.svg"
                        loading="lazy"
                        class="q-ma-none absolute-bottom-right"
                        fit="fill"
                        width="50px"
                        height="10px"
                    />
                    <span v-if="item.available && item.available.indexOf('PC')>=0" class="absolute-top-right" style="color:black;opacity:0.9">
                        <q-chip dense><q-icon dense name="mdi-desktop-tower-monitor" size="xs" /></q-chip>
                    </span>
                </q-img>
                <q-card-actions vertical class="q-pa-xs" align="around">
                    <div class="q-pa-xs flex flex-center">
                        <q-btn flat round dense size="sm" icon="mdi-microsoft-xbox" @click="open(item.url)"></q-btn>
                        <q-btn flat round dense size="sm" icon="shopping_bag" @click="open('ms-windows-store://pdp/?productid='+item.xid)"></q-btn>
                        <q-btn v-if="state.user.username!='' && item.own==null" flat round dense size="sm" icon="star_border" :loading="loadMy" @click="addLibrary(item.xid)" />
                        <q-btn v-if="state.user.username!='' && item.own=='y'" flat round dense size="sm" icon="star" color="orange" :loading="loadMy" @click="delLibrary(item.xid,item.price_thai)" />
                    </div>
                    <div class="text-subtitle2">
                        <span :style="'font-size:12px;color:'+fontColor(item.priceMode)" class="q-px-sm">
                            <q-img :src="'/images/flag/'+item.flag+'.svg'" width="22px" fit="fill" class="q-mb-sm q-mr-xs" style="border:1px solid #D5D8DC;"></q-img>
                            <i v-if="item.priceMode!=''">-{{item.pricePercent}}%&nbsp;</i>
                            <span style="font-size:14px;">{{item.price_thai != 0 ? '฿'+item.price_thai : 'FREE' }}</span>
                        </span>
                    </div>
                    <div class="text-subtitle2">
                        <span v-if="item.priceMode!=''" :style="'font-size:12px;color:'+fontColor(item.priceMode)"><i>Deal Ends {{diffDate(item.dateEnd)}}</i></span>
                        <span v-else>&nbsp;</span>
                    </div>
                </q-card-actions>
            </q-card>

            <!------------------- Nintendo ------------------------>
            <q-card v-else-if="state.device=='nintendo' || item.nid" :class="bgColor(item)+' column my-card'" :style="imageHeight(item)">
                <div v-if="state.device=='home'" style="background-color:red">
                    <q-img
                        src="/images/nin.png"
                        loading="lazy"
                        class="q-ma-xs"
                        height="15px"
                        fit="contain"
                    >
                    </q-img>
                </div>
                <q-img
                    :src="'https://image.numcreator.com/nintendo/'+item.nid+'.png'"
                    loading="lazy"
                    spinner-color="white"
                    class="col cursor-pointer q-hoverable"
                    @click="detail(item)"
                    fit="contain"
                >
                    <span v-if="item.zone" class="absolute-top-left">
                        <q-chip color="red" text-color="white" icon="mdi-ticket-percent-outline" size="sm"
                            :label="item.zone==1?'ALL':item.zone==3?'JP':'US'" />
                    </span>
                </q-img>
                <q-card-actions vertical class="q-pa-xs" align="around">
                    <div class="q-pa-xs flex flex-center">
                        <q-btn flat round dense size="sm" icon="mdi-nintendo-switch" @click="openNin(item.nsuid,item.flag,item.url1)"></q-btn>
                        <q-btn v-if="state.user.username!='' && item.own==null" flat round dense size="sm" icon="star_border" :loading="loadMy" @click="addLibrary(item.nid)" />
                        <q-btn v-if="state.user.username!='' && item.own=='y'" flat round dense size="sm" icon="star" color="orange" :loading="loadMy" @click="delLibrary(item.nid,item.price_thai)" />
                    </div>
                    <div class="text-subtitle2">
                        <span :style="'font-size:12px;color:'+fontColor(item.priceMode)" class="q-px-sm">
                            <q-img :src="'/images/flag/'+item.flag.toLowerCase()+'.svg'" width="22px" fit="fill" class="q-mb-sm q-mr-xs" style="border:1px solid #D5D8DC;"></q-img>
                            <i v-if="item.priceMode!=''">-{{item.pricePercent}}%&nbsp;</i>
                            <span style="font-size:15px;">{{item.price_thai != 0 ? '฿'+item.price_thai : 'FREE' }}</span>
                        </span>
                    </div>
                    <div class="text-subtitle2">
                        <span v-if="item.priceMode!=''" :style="'font-size:12px;color:'+fontColor(item.priceMode)"><i>Deal Ends {{diffDate(item.dateEnd)}}</i></span>
                        <span v-else>&nbsp;</span>
                    </div>
                </q-card-actions>
            </q-card>

            <!------------------- Steam ------------------------>
            <q-card v-if="state.device=='steam' || item.stid" :class="bgColor(item)+' column my-card'" :style="imageHeight(item)">
                <div v-if="state.device=='home'" style="background-color:indigo">
                    <q-img
                        src="/images/steam.png"
                        loading="lazy"
                        class="q-ma-xs"
                        height="15px"
                        fit="contain"
                    >
                    </q-img>
                </div>
                <q-img
                    :src="'https://image.numcreator.com/steam/'+item.stid+'.png'"
                    loading="lazy"
                    spinner-color="white"
                    class="col cursor-pointer q-hoverable"
                    @click="detail(item)"
                    :fit="state.device=='home'?'contain':''"
                >
                </q-img>
                <q-card-actions vertical class="q-pa-xs" align="around">
                    <div class="text-subtitle2">
                        <span v-if="item.mod != null" style="color:orange">MOD </span>
                        <span v-if="item.thai == 1" style="color:red">TH </span>
                        <span>{{ item.productKind.toUpperCase() }}</span>
                        <span v-if="item.disPercent!='0'" style="font-size:16px;color:#02AD14">
                            <i>&nbsp;-{{item.disPercent}}%&nbsp;</i>
                        </span>
                    </div>
                    <div class="q-pa-none flex flex-center">
                        <q-btn flat round dense size="sm" icon="mdi-steam" @click="open(item.url)"></q-btn>
                        <q-btn v-if="state.user.username!='' && item.own==null" flat round dense size="sm" icon="star_border" :loading="loadMy" @click="addLibrary(item.stid)" />
                        <q-btn v-if="state.user.username!='' && item.own=='y'" flat round dense size="sm" icon="star" color="orange" :loading="loadMy" @click="delLibrary(item.stid,item.disPrice)" />
                    </div>
                    <div class="text-subtitle2">
                        <span v-if="item.disPercent!='0'">
                            <span style="font-size:14px;"><del>{{item.listPrice}}</del>&nbsp;</span>
                            <span style="font-size:16px;color:#02AD14">{{item.disPrice}}</span>
                        </span>
                        <span v-else>
                            <span style="font-size:16px;">{{item.disPrice}}</span>
                        </span>
                    </div>
                </q-card-actions>
            </q-card>

            <!------------------- AppleTV ------------------------>
            <q-card v-else-if="state.device=='appletv' || item.aid" :class="bgColor(item)+' my-card'" :style="imageHeight(item)">
                <div v-if="state.device=='home'" style="background-color:#3E3E3E">
                    <q-img
                        src="/images/apple.png"
                        loading="lazy"
                        class="q-ma-xs"
                        height="15px"
                        fit="contain"
                    >
                    </q-img>
                </div>
                <q-img
                    :src="item.status=='E'?'https://image.numcreator.com/apple/'+item.aid+'.png':item.artwork"
                    loading="lazy"
                    spinner-color="white"
                    class="cursor-pointer q-hoverable"
                    @click="detail(item)"
                >
                    <q-chip v-if="item.kind=='movie'" size="md" class="q-ma-none absolute-bottom-left" dense style="opacity:0.9">
                        <q-avatar>
                            <img :src="'/images/other/tomatometer-'+(item.scoreRT>80?'3':item.scoreRT>60?'2':item.scoreRT>0?'1':'0')+'.svg'">
                        </q-avatar>
                        <b v-if="item.scoreRT!=0">{{ item.scoreRT }}</b>
                    </q-chip>
                    <q-chip v-if="item.kind=='movie' && item.scoreUS!=0" size="md" class="q-ma-none absolute-bottom-right" dense style="opacity:0.9">
                        <q-avatar>
                            <img src="/images/other/tomatometer-us.svg">
                        </q-avatar>
                        <b>{{ item.scoreUS }}</b>
                    </q-chip>
                    <span v-if="item.kind=='movie' && item.audiothai!=0" class="absolute-top-left" style="color:black;opacity:0.9">
                        <q-chip class="q-pa-none" square dense><q-icon dense name="volume_up" size="xs" /></q-chip>
                    </span>
                    <span v-if="item.kind=='movie' && item.subthai!=0" class="absolute-top-right" style="color:black;opacity:0.9">
                        <q-chip class="q-pa-none" square dense><q-icon dense name="mdi-closed-caption-outline" size="sm" /></q-chip>
                    </span>
                </q-img>
                <q-card-actions vertical class="q-pa-xs" align="around">
                    <div class="q-pa-xs flex flex-center">
                        <q-btn v-if="item.kind=='movie' && item.status=='E'" flat round dense size="sm" icon="mdi-apple" @click="open(item.url2)"></q-btn>
                        <q-btn flat round dense size="sm" icon="mdi-music" @click="open(item.url)"></q-btn>
                        <q-btn v-if="state.user.username!='' && item.own==null" flat round dense size="sm" icon="star_border" :loading="loadMy" @click="addLibrary(item.aid)" />
                        <q-btn v-if="state.user.username!='' && item.own=='y'" flat round dense size="sm" icon="star" color="orange" :loading="loadMy" @click="delLibrary(item.aid,item.price_now)" />
                    </div>
                    <div class="text-subtitle2" style="font-size:16px">
                        <span v-if="item.price_last && compareDate(item.cdate)" class="q-px-sm" :style="'color:'+(parseInt(item.price_last)>parseInt(item.price_now) ? '#00C853':'')">
                            <del class="q-pr-xs" style="font-size: 12px;">฿{{item.price_last}}</del> ฿{{item.price_now}}
                        </span>
                        <span v-else-if="item.status=='E'" class="q-px-sm">
                            {{ item.preorder==1?'Preorder':'' }} {{item.price_now}}
                        </span>
                        <span v-else class="q-px-sm">
                            REMOVE
                        </span>
                    </div>
                </q-card-actions>
            </q-card>
        
        </div>
    </template>

    <!------------------- Show View Detail ------------------------>
    <template v-if="!loading && state.view">
        <div v-for="item in vItems" :key="item" class="col-xs-12 col-sm-6 col-md-4" :style="cardWidth">

            <!------------------- Playstation ------------------------>
            <q-card v-if="state.device=='playstation' || item.sid" v-ripple :class="bgColor(item)+' my-card row q-hoverable'" :style="imageHeight(item)">
                <span class="q-focus-helper"></span>
                <div class="q-pa-sm col-5">
                    <q-card class="my-card q-hoverable cursor-pointer" @click="detail(item)">
                        <div style="position: absolute;right:6px">
                            <span style="color:#EEB400" v-if="item.essentials==1"><b>Essentials</b></span>
                            <span style="color:#EEB400" v-if="item.plus"><b>{{item.plus}}</b></span>
                        </div>
                        <span class="q-focus-helper"></span>
                        <div v-if="item.platform.indexOf('PS4')>=0" style="background-color:#2E86C1">
                            <q-img
                                src="/images/ps4.png"
                                loading="lazy"
                                class="q-ma-xs"
                                width="40%"
                            >
                            </q-img>
                        </div>
                        <div v-else style="background-color:white" >
                            <q-img
                                src="/images/ps5.png"
                                loading="lazy"
                                class="q-ma-xs"
                                width="40%"
                            >
                            </q-img>
                        </div>
                        <q-img
                            :src="posterURL(item)"
                            loading="lazy"
                            spinner-color="white"
                            class="cursor-pointer q-hoverable"
                        >
                        </q-img>
                        <q-card-actions vertical class="q-pa-none" align="around">
                            <div class="text-subtitle2" v-if="store=='th'">
                                <span v-if="item.sthai == 1" style="color:red">TH</span>
                                <span v-if="item.detail=='Game'"> EN</span>
                                {{item.detail.replace('English','EN').replace('Chinese','ZH').replace('Korean','KO').replace('Japanese','JP').replace('Game','&nbsp;').replace('Ver.','').replace(/\//g," ")}}
                            </div>
                        </q-card-actions>
                    </q-card>
                </div>
                <div class="column q-pa-sm col-7">
                    <div class="col-9" @click="detail(item)">
                        <div class="text-weight-bold q-pb-sm" style="font-size:16px">{{item.title}}</div>
                        <div><b>Release:</b> {{item.releaseDate}}</div>
                    </div>
                    <div class="col text-right">
                        <q-btn flat round dense size="sm" icon="mdi-sony-playstation" @click="open('https://store.playstation.com/'+storeURL+'/product/'+item.sid)"></q-btn>
                        <q-btn v-if="state.user.username!='' && item.own==null" flat round dense size="sm" icon="star_border" :loading="loadMy" @click="addLibrary(item.sid)" />
                        <q-btn v-if="state.user.username!='' && item.own=='y'" flat round dense size="sm" icon="star" color="orange" :loading="loadMy" @click="delLibrary(item.sid,item.price_thai)" />
                        <br/>
                        <span :style="'font-size:12px;color:'+fontColor(item.priceMode)" class="q-px-sm">
                            <i v-if="item.priceMode!=''">-{{item.pricePercent}}%&nbsp;</i>
                            <span style="font-size:16px;">{{item.price_thai != 0 ? '฿'+item.price_thai : 'FREE' }}</span>
                        </span>
                    </div>
                </div>
            </q-card>

            <!------------------- Xbox ------------------------>
            <q-card v-else-if="state.device=='xbox' || item.xid" v-ripple :class="bgColor(item)+' my-card row q-hoverable'" :style="imageHeight(item)">
                <span class="q-focus-helper"></span>
                <div class="q-pa-sm col-5">
                    <div v-if="state.device=='home'" style="background-color:green">
                        <q-img
                            src="/images/xbox.png"
                            loading="lazy"
                            class="q-ma-xs"
                            height="10px"
                            fit="contain"
                        >
                        </q-img>
                    </div>
                    <q-img
                        :src="'https://image.numcreator.com/xbox/'+item.xid+'.png'"
                        loading="lazy"
                        spinner-color="white"
                        class="rounded-borders cursor-pointer q-hoverable"
                        @click="detail(item)"
                    >
                        <q-img v-if="item.xs==1 || item.available=='XboxSeriesX'"
                            src="/images/xs.png"
                            loading="lazy"
                            class="q-ma-none absolute-bottom-left"
                            fit="fill"
                            width="60px"
                            height="10px"
                            style="opacity:0.9"
                        />
                        <span v-if="item.thai!=null" class="q-pl-sm absolute-bottom text-center" style="font-size:14px;color:black;opacity:0.6">
                            <q-chip dense><b>TH</b></q-chip>
                        </span>
                        <q-img v-if="item.gp==1"
                            src="/images/gp.svg"
                            loading="lazy"
                            class="q-ma-none absolute-bottom-right"
                            fit="fill"
                            width="50px"
                            height="10px"
                        />
                        <span v-if="item.available && item.available.indexOf('PC')>=0" class="absolute-top-right" style="color:black;opacity:0.9">
                            <q-chip dense><q-icon dense name="mdi-desktop-tower-monitor" size="xs" /></q-chip>
                        </span>
                    </q-img>
                </div>
                <div class="column q-pa-sm col-7">
                    <div class="col-9" @click="detail(item)">
                        <div class="text-weight-bold q-pb-sm" style="font-size:16px">{{item.title}}</div>
                        <div v-if="item.properties">
                            <img v-if="item.properties.indexOf('4k')>0" src="/images/other/4k.svg" height="14" class="q-pr-xs"/>
                            <img v-if="item.properties.indexOf('HDR')>0" src="/images/other/hdr.svg" height="14" class="q-pr-xs"/>
                            <img v-if="item.properties.indexOf('60')>0" src="/images/other/60.png" height="14" class="q-pr-xs"/>
                            <img v-if="item.properties.indexOf('120')>0" src="/images/other/120.png" height="14" class="q-pr-xs"/>
                            <img v-if="item.properties.indexOf('VRR')>0" src="/images/other/vrr.png" height="14" class="q-pr-xs"/>
                            <img v-if="item.properties.indexOf('Ray')>0" src="/images/other/rtx.png" height="14" class="q-pr-xs"/>
                            <img v-if="item.properties.indexOf('Atmos')>0" src="/images/other/atmos.svg" height="14" class="q-pr-xs"/>
                            <img v-if="item.properties.indexOf('DTSX')>0" src="/images/other/dtsx.png" height="14" class="q-pr-xs"/>
                            <img v-if="item.properties.indexOf('Single')>0" src="/images/other/single.png" height="14" class="q-pr-xs"/>
                            <img v-if="item.properties.indexOf('Coop')>0" src="/images/other/coop.png" height="14" class="q-pr-xs"/>
                            <img v-if="item.properties.indexOf('Multi')>0" src="/images/other/multi.png" height="14" class="q-pr-xs"/>
                            <img v-if="item.properties.indexOf('XPA')>0" src="/images/other/xpa.png" height="14" class="q-pr-xs"/>
                        </div>
                        <div><b>Release:</b> {{item.releaseDate}}</div>
                    </div>
                    <div class="col text-right">
                        <span v-if="item.priceMode!=''" class="q-pr-sm" :style="'font-size:12px;color:'+fontColor(item.priceMode)"><i>{{diffDate(item.dateEnd)}}</i></span>
                        <q-btn flat round dense size="sm" icon="mdi-microsoft-xbox" @click="open(item.url)"></q-btn>
                        <q-btn flat round dense size="sm" icon="shopping_bag" @click="open('ms-windows-store://pdp/?productid='+item.xid)"></q-btn>
                        <q-btn v-if="state.user.username!='' && item.own==null" flat round dense size="sm" icon="star_border" :loading="loadMy" @click="addLibrary(item.xid)" />
                        <q-btn v-if="state.user.username!='' && item.own=='y'" flat round dense size="sm" icon="star" color="orange" :loading="loadMy" @click="delLibrary(item.xid,item.price_thai)" />
                        <br/>
                        <span :style="'font-size:12px;color:'+fontColor(item.priceMode)" class="q-px-sm">
                            <q-img :src="'/images/flag/'+item.flag+'.svg'" width="22px" fit="fill" class="q-mb-sm q-mr-xs"></q-img>
                            <i v-if="item.priceMode!=''">-{{item.pricePercent}}%&nbsp;</i>
                            <span style="font-size:15px;">{{item.price_thai != 0 ? '฿'+item.price_thai : 'FREE' }}</span>
                        </span>
                    </div>
                </div>
            </q-card>

            <!------------------- Nintendo ------------------------>
            <q-card v-else-if="state.device=='nintendo' || item.nid" v-ripple :class="bgColor(item)+' my-card row q-hoverable'" :style="imageHeight(item)">
                <span class="q-focus-helper"></span>
                <div class="q-pa-sm col-5">
                    <div v-if="state.device=='home'" style="background-color:red">
                        <q-img
                            src="/images/nin.png"
                            loading="lazy"
                            class="q-ma-xs"
                            height="10px"
                            fit="contain"
                        >
                        </q-img>
                    </div>
                    <q-img
                        :src="'https://image.numcreator.com/nintendo/'+item.nid+'.png'"
                        loading="lazy"
                        spinner-color="white"
                        class="col rounded-borders cursor-pointer q-hoverable"
                        @click="detail(item)"
                        fit="contain"
                    >
                        <span v-if="item.zone" class="absolute-top-left">
                            <q-chip color="red" text-color="white" icon="mdi-ticket-percent-outline" size="sm"
                                :label="item.zone==1?'ALL':item.zone==3?'JP':'US'" />
                        </span>
                    </q-img>
                    <div class="q-pb-xl"></div>
                </div>
                <div class="column q-pa-sm col-7">
                    <div class="col-9" @click="detail(item)">
                        <div class="text-weight-bold q-pb-sm" style="font-size:16px">{{item.title}}</div>
                        <div><b>Release:</b> {{item.releaseDate}}</div>
                    </div>
                    <div class="col text-right">
                        <span v-if="item.priceMode!=''" class="q-pr-sm" :style="'font-size:12px;color:'+fontColor(item.priceMode)"><i>Deal Ends {{diffDate(item.dateEnd)}}</i></span>
                        <q-btn flat round dense size="sm" icon="mdi-nintendo-switch" @click="openNin(item.nsuid,item.flag,item.url1)"></q-btn>
                        <q-btn v-if="state.user.username!='' && item.own==null" flat round dense size="sm" icon="star_border" :loading="loadMy" @click="addLibrary(item.nid)" />
                        <q-btn v-if="state.user.username!='' && item.own=='y'" flat round dense size="sm" icon="star" color="orange" :loading="loadMy" @click="delLibrary(item.nid,item.price_thai)" />
                        <br/>
                        <span :style="'font-size:12px;color:'+fontColor(item.priceMode)" class="q-px-sm">
                            <q-img :src="'/images/flag/'+item.flag.toLowerCase()+'.svg'" width="22px" fit="fill" class="q-mb-sm q-mr-xs" style="border:1px solid #D5D8DC;"></q-img>
                            <i v-if="item.priceMode!=''">-{{item.pricePercent}}%&nbsp;</i>
                            <span style="font-size:15px;">{{item.price_thai != 0 ? '฿'+item.price_thai : 'FREE' }}</span>
                        </span>
                    </div>
                </div>
            </q-card>

            <!------------------- Steam ------------------------>
            <q-card v-else-if="state.device=='steam' || item.stid" :class="bgColor(item)+' my-card row q-hoverable'" :style="imageHeight(item)">
                <span class="q-focus-helper"></span>
                <div class="q-pa-sm col-5">
                    <q-card class="my-card q-hoverable cursor-pointer" @click="detail(item)">
                        <div v-if="state.device=='home'" style="background-color:indigo">
                            <q-img
                                src="/images/steam.png"
                                loading="lazy"
                                class="q-ma-xs"
                                height="10px"
                                fit="contain"
                            >
                            </q-img>
                        </div>
                        <q-img
                            :src="'https://image.numcreator.com/steam/'+item.stid+'.png'"
                            loading="lazy"
                            spinner-color="white"
                            class="cursor-pointer q-hoverable"
                        >
                        </q-img>
                        <q-card-actions vertical class="q-pa-none" align="around">
                            <div class="text-subtitle2" v-if="store=='th'">
                                <span v-if="item.mod != null" style="color:orange">MOD </span>
                                <span v-if="item.thai == 1" style="color:red">TH </span>
                                <span>{{ item.productKind.toUpperCase() }}</span>
                            </div>
                        </q-card-actions>
                    </q-card>
                </div>
                <div class="column q-pa-sm col-7">
                    <div class="col-9" @click="detail(item)">
                        <div class="text-weight-bold q-pb-sm" style="font-size:16px">{{item.title}}</div>
                        <div><b>Release:</b> {{item.releaseDate}}</div>
                    </div>
                    <div class="col text-right">
                        <q-btn flat round dense size="sm" icon="mdi-steam" @click="open(item.url)"></q-btn>
                        <q-btn v-if="state.user.username!='' && item.own==null" flat round dense size="sm" icon="star_border" :loading="loadMy" @click="addLibrary(item.stid)" />
                        <q-btn v-if="state.user.username!='' && item.own=='y'" flat round dense size="sm" icon="star" color="orange" :loading="loadMy" @click="delLibrary(item.stid,item.disPrice)" />
                        <br/>
                        <span v-if="item.disPercent!='0'">
                            <span style="font-size:14px;"><del>{{item.listPrice}}</del>&nbsp;</span>
                            <span style="font-size:16px;color:#02AD14">-{{item.disPercent}}% ฿{{item.disPrice}}</span>
                        </span>
                        <span v-else>
                            <span style="font-size:16px;">{{item.disPrice}}</span>
                        </span>
                    </div>
                </div>
            </q-card>

            <!------------------- AppleTV ------------------------>
            <q-card v-else-if="state.device=='appletv' || item.aid" v-ripple :class="bgColor(item)+' my-card row q-hoverable'" :style="imageHeight(item)">
                <span class="q-focus-helper"></span>
                <div class="q-pa-sm col-5">
                    <div v-if="state.device=='home'" style="background-color:#3E3E3E">
                        <q-img
                            src="/images/apple.png"
                            loading="lazy"
                            class="q-ma-xs"
                            height="10px"
                            fit="contain"
                        >
                        </q-img>
                    </div>
                    <q-img
                        :src="item.status=='E'?'https://image.numcreator.com/apple/'+item.aid+'.png':item.artwork"
                        loading="lazy"
                        spinner-color="white"
                        class="rounded-borders cursor-pointer q-hoverable"
                        @click="detail(item)"
                    >
                        <q-chip v-if="item.kind=='movie'" size="md" class="q-ma-none absolute-bottom-left" dense style="opacity:0.9">
                            <q-avatar>
                                <img :src="'/images/other/tomatometer-'+(item.scoreRT>80?'3':item.scoreRT>60?'2':item.scoreRT>0?'1':'0')+'.svg'">
                            </q-avatar>
                            <b v-if="item.scoreRT!=0">{{ item.scoreRT }}</b>
                        </q-chip>
                        <q-chip v-if="item.kind=='movie' && item.scoreUS!=0" size="md" class="q-ma-none absolute-bottom-right" dense style="opacity:0.9">
                            <q-avatar>
                                <img src="/images/other/tomatometer-us.svg">
                            </q-avatar>
                            <b>{{ item.scoreUS }}</b>
                        </q-chip>
                        <span v-if="item.kind=='movie' && item.audiothai!=0" class="absolute-top-left" style="color:black;opacity:0.9">
                            <q-chip class="q-pa-none" square dense><q-icon dense name="volume_up" size="xs" /></q-chip>
                        </span>
                        <span v-if="item.kind=='movie' && item.subthai!=0" class="absolute-top-right" style="color:black;opacity:0.9">
                            <q-chip class="q-pa-none" square dense><q-icon dense name="mdi-closed-caption-outline" size="sm" /></q-chip>
                        </span>
                        <!-- <q-icon v-if="item.kind=='movie' && item.audiothai!=0" class="q-ma-xs absolute-top-left" size="sm" name="volume_up" color="grey-4">
                        </q-icon>
                        <q-icon v-if="item.kind=='movie' && item.subthai!=0" class="q-ma-xs absolute-top-right" size="sm" name="closed_caption" color="grey-4">
                        </q-icon> -->
                    </q-img>
                </div>
                <div class="column q-pa-sm col-7">
                    <div class="col-9" @click="detail(item)">
                        <div class="text-weight-bold q-pb-sm" style="font-size:16px">{{item.name}}</div>
                        <div v-if="item.kind=='movie'">
                            <img v-if="item.has4K == 1" src="/images/other/4k.svg" height="14" class="q-pr-xs"/>
                            <img v-else src="/images/other/hd.svg" height="14" class="q-pr-xs"/>
                            <img v-if="item.hasHDR == 1" src="/images/other/hdr.svg" height="14" class="q-pr-xs"/>
                            <img v-if="item.hasDolbyVision == 1" src="/images/other/vision.svg" height="14" class="q-pr-xs"/>
                            <img v-if="item.hasDolbyAtmos == 1" src="/images/other/atmos.svg" height="14" class="q-pr-xs"/>
                            <div v-if="item.status == E"><b>Duration:</b> {{item.duration}} Minute</div>
                        </div>
                        <div><b>Release:</b> {{item.releaseDate}}</div>
                    </div>
                    <div class="col text-right" style="font-size:16px">
                        <q-btn v-if="item.kind=='movie' && item.status=='E'" flat round dense size="sm" icon="mdi-apple" @click="open(item.url2)"></q-btn>
                        <q-btn flat round dense size="sm" icon="mdi-music" @click="open(item.url)"></q-btn>
                        <q-btn v-if="state.user.username!='' && item.own==null" flat round dense size="sm" icon="star_border" :loading="loadMy" @click="addLibrary(item.aid)" />
                        <q-btn v-if="state.user.username!='' && item.own=='y'" flat round dense size="sm" icon="star" color="orange" :loading="loadMy" @click="delLibrary(item.aid,item.price_now)" />
                        <br/>
                        <span v-if="item.status=='E' && item.price_last" class="q-px-sm" :style="'color:'+(item.price_last>item.price_now ? 'green':'')">
                            <del class="q-pr-xs" style="font-size: 12px;">฿{{item.price_last}}</del> ฿{{item.price_now}}
                        </span>
                        <span v-else-if="item.status=='E'" class="q-px-sm">
                            {{ item.preorder==1?'Preorder':'' }} {{item.price_now}}
                        </span>
                        <span v-else class="q-px-sm">
                            REMOVE
                        </span>
                    </div>
                </div>
            </q-card>

        </div>
    </template>
    
    <!------------------- Pagination ------------------------>
    <div v-if="totalPage>1" class="col-12 q-pt-md flex flex-center">
        <q-pagination
            v-model="page"
            :max="totalPage"
            :max-pages="7"
            padding="1px 9px"
            direction-links
            @click="toTop"
            :color="state.color"
        />
    </div>


    <!------------------- Loading ------------------------>
    <div v-if="loading" class="col-12 q-pt-lg flex flex-center">
        <q-circular-progress
            indeterminate
            :color="state.color"
            size="75px"
            :thickness="0.6"
            center-color="grey-7"
            class="q-ma-md"
        />
    </div>


    <!------------------- No data ------------------------>
    <div v-if="nodata">
        <div class="text-h1 text-weight-medium" style="color:#D5D8DC ">(-_-*)</div>
        <div class="text-h5 q-pa-md" style="color:#D5D8DC ">No Data</div>
    </div>


    <!------------------- Detail ------------------------>
    <q-dialog v-model="showDetail" >
        <ShowDetail :formData="item" :key="refresh"/>
    </q-dialog>

</template>

<style>
    .q-dialog__backdrop
    {
        backdrop-filter: blur(7px);
    }
    #capture .q-dialog__backdrop
    {
        backdrop-filter: color(black);
    }
</style>

<script setup>

    /*------------------------------- Import -----------------------------*/
    import { ref,onBeforeMount,getCurrentInstance,toRef,computed} from 'vue';
    import { useState } from '../stores/webStore';
    import ShowDetail from '../components/ShowDetail.vue';
    import * as XLSX from 'xlsx/xlsx.mjs';
    import axios from 'axios';
    import { scroll,Screen } from 'quasar'
    import { useQuasar } from 'quasar';
    const { getScrollTarget, setVerticalScrollPosition } = scroll
    const state = useState();
    const props = defineProps({
        formData: {},
    })
    const $q = useQuasar();
    
    /*------------------------------- Data -----------------------------*/
    let routeName = ref(state.pageName);
    let loc = ref(state.loc);
    let formData = toRef(props, 'formData').value;
    let store = loc.value.store;
    let poster = getCurrentInstance().appContext.config.globalProperties.$poster;
    let hostUrl = getCurrentInstance().appContext.config.globalProperties.$hostUrl;
    let showDetail = ref(false);
    let page = ref(1);
    let perPage = 24;
	let totalPage = ref(0);
    let items = ref([]);
    let item = ref({});
    let begin = ref();
    let loading = ref(false);
    let nodata = ref(false);
    let order = ref(formData.order);
    let orders = ref([
        { label: 'Last Update', value: 'cdate desc' },
        { label: 'Recently Added', value: 'ddate desc' },
        { label: 'Release new', value: 'releaseDate desc' },
        { label: 'Release old', value: 'releaseDate' },
        { label: 'A -> Z', value: 'title' },
        { label: 'Z -> A', value: 'title desc' },
    ]);
    let tmpItems = ref([]);
    // let filFlag = ref([
    //     { label: 'All', value: 'all' },
    //     { label: 'US', value: 'us' },
    //     { label: 'AR', value: 'ar' },
    //     { label: 'TR', value: 'tr' },
    //     { label: 'IN', value: 'in' },
    //     { label: 'BR', value: 'br' },
    //     { label: 'TH', value: 'th' },
    // ]);
    // let filFlagNin = ref(['all','US','AR','CA','CL','CO','MX','PE','AT','AU','BE','BG','CH','CY',
    //                 'CZ','DE','DK','EE','ES','FI','FR','GB','GR','HK','HR','HU','IE','IT','LT',
    //                 'LU','LV','MT','NL','NO','NZ','PL','PT','RO','RU','SE','SI','SK','ZA','JP']);
    let filFlagNin = ref(['ALL']);
    let filterFlag = ref('ALL');
    let filPrice = ref([
        { label: 'All', value: 'all' },
        { label: '0-99', value: '0-99.99' },
        { label: '100-199', value: '100-199.99' },
        { label: '200-499', value: '200-499.99' },
        { label: '500-999', value: '500-999.99' },
        { label: '>1000', value: '1000' },
    ]);
    let filterPrice = ref('all');
    let loadMy = ref(false);
    let refresh = ref(0);
    let total = ref(0);
    let itmEx = [];
    let filDevice = ref([
        { label: 'All', value: 'all' },
        { label: 'AppleTV', value: 'aid' },
        { label: 'Playstation', value: 'sid' },
        { label: 'Xbox', value: 'xid' },
        { label: 'Nintendo', value: 'nid' },
        { label: 'Steam', value: 'stid' },
    ]);
    let filterDevice = ref('all');

    if(state.device=='playstation' || state.device=='appletv'){
        orders.value.push({ label: 'Price Low -> High', value: 'plow' });
        orders.value.push({ label: 'Price High -> Low', value: 'phigh' });
    } else if(state.device=='xbox' || state.device=='nintendo'){
        orders.value = [
            { label: 'Last Update', value: 'cdate desc' },
            { label: 'Deal Ends', value: 'enddate' },
            { label: 'Recently Added', value: 'ddate desc' },
            { label: 'Release new', value: 'releaseDate desc' },
            { label: 'Release old', value: 'releaseDate' },
            { label: 'A -> Z', value: 'title' },
            { label: 'Z -> A', value: 'title desc' },
        ];
    } else if(state.device=='steam'){
        orders.value.push({ label: 'Price Low -> High', value: 'disPrice' });
        orders.value.push({ label: 'Price High -> Low', value: 'disPrice desc' });
        orders.value.push({ label: 'Pecent Low -> High', value: 'percentLow' });
        orders.value.push({ label: 'Pecent High -> Low', value: 'percentHigh' });
    }

    if(state.device=='appletv'){
        orders.value.push({ label: 'Rotten High -> Low', value: 'rhigh' });
        orders.value.push({ label: 'Audience High -> Low', value: 'ahigh' });
    } 

    /*------------------------------- Before Mount -----------------------------*/

    onBeforeMount(() => {
        getData();
    })

    /*------------------------------- Computed -----------------------------*/

    const cardWidth = computed(() => {
        let width = '';
        if(formData.limit == 20){
            width = 'width:170px';
            if(state.view) {
                width = 'width:380px';
            } else {
                if(state.device=='steam') width = 'width:220px';
                if(state.device=='home') width = '';
            }
        } 
        else {
            if(state.device=='steam' && !state.view) {
                if(Screen.name=='md' || Screen.name=='sm') width = 'width:240px';
                if(Screen.name=='lg') width = 'width:230px';
            } 
        }
        return width;
    })

    const vItems = computed(() => {
        if(items.value.length != undefined){
            return items.value.slice((page.value - 1)* perPage, page.value* perPage)
        }
        return {}
    })

    const storeURL = computed(() => {
        switch (store) {
            case 'th': return 'en-th'
            case 'us': return 'en-us'
            case 'jp': return 'ja-jp'
            case 'tr': return 'tr-tr'
        }
        return 'en-th'
    })

    /*------------------------------- Method -----------------------------*/

    async function getData(){
        loading.value = true;
        page.value = 1;
        totalPage.value = 0;
        nodata.value = false;
        formData.device = state.device;
        formData.cType = state.cType?'visa':'master';
        formData.rate = state.rate;
        filterFlag.value = 'ALL';
        filterPrice.value = 'all';
        total.value = 0;
        if(formData.productKind=='hot') state.showHot = false;
        await axios.post(hostUrl+'api/getData', JSON.stringify(formData))
        .then(function (response) {
            if(!response.data){
                alert("ERROR Please contact admin")
            }else{
                items.value = response.data.data;
                tmpItems.value = response.data.data;
                totalPage.value = Math.ceil(items.value.length/perPage);
                if(items.value.length == 0) {
                    if(formData.productKind!='hot') nodata.value = true;
                } else {
                    if(formData.productKind=='hot') state.showHot = true;
                    itmEx = [];
                    for(let obj of items.value){
                        if(state.device=='nintendo' || state.device=='xbox'){
                            if(!filFlagNin.value.find((flag)=>flag.toUpperCase()==obj.flag.toUpperCase())){
                                filFlagNin.value.push(obj.flag.toUpperCase())
                            }
                        }
                        if(routeName.value == 'library')
                        total.value += parseFloat(state.device=='appletv'?obj.price_now:obj.price_thai.replace(/,/g,""));
                        
                        let ex = {};
                        ex.Name = obj.title;
                        if(state.device=='appletv'){
                            ex['4k'] = obj.has4K==1?'Y':'';
                            ex.DolbyVision = obj.hasDolbyVision==1?'Y':'';
                            ex.HDR = obj.hasHDR==1?'Y':'';
                            ex.DolbyAtmos = obj.hasDolbyAtmos==1?'Y':'';
                            ex.Type = obj.kind;
                            ex.Price = obj.price_now;
                        } else if(state.device=='nintendo' || state.device=='xbox'){
                            ex.Developer = obj.developerName;
                            ex.Release = obj.releaseDate;
                            ex.DealEnd = obj.dateEnd;
                            ex.Percent = obj.pricePercent;
                            ex.Country = obj.flag;
                            ex.Price = obj.price_thai;
                        } else if(state.device=='playstation'){
                            ex.Developer = obj.provider;
                            ex.Release = obj.releaseDate;
                            ex.Percent = obj.pricePercent;
                            ex.Price = obj.price_thai;
                        }
                        itmEx.push(ex);
                    }
                    total.value = total.value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                }
            }
        })
        .catch(function (error) {
            console.log(error);
        });
        loading.value = false;
    }

    async function detail(obj){
        showDetail.value = true;
        item.value = obj;
        refresh.value++;
    }

    function fontColor(mode){
        if(mode=='d'){
            return '#02AD14';
        } else if(mode=='m'){
            return '#D99700';
        } else {
            return ''
        }
    }

    function posterURL(item){
        if(store == 'th'){
            return poster+'images/poster/'+item.sid+'.png';
        } else {
            return item.poster;
        }
    }

    function open(id){
        window.open(id);
    }

    function openNin(id,flag,url1){
        let type = 'titles';
        let url = '';
        if(id.startsWith('7001')) type = 'titles';
        else if(id.startsWith('7007')) type = 'bundles';
        else type = 'aocs';
        if(flag=='JP') url = 'https://ec.nintendo.com/JP/ja/'+type+'/'+id;
        else if(flag=='HK') url = 'https://ec.nintendo.com/HK/zh/'+type+'/'+id;
        else if(['US','AR','CA','CL','CO','MX','PE'].indexOf(flag)>=0){
            let link = '';
            if(flag=='US' || flag=='CA') link = 'en-'+flag.toLowerCase();
            else link = 'es-'+flag.toLowerCase();
            if(url1){
                url = "https://www.nintendo.com/"+link+url1;
            } else {
                url = "https://www.nintendo.com/"+link;
            }
        }
        else url = 'https://ec.nintendo.com/'+flag+'/en/'+type+'/'+id;
        window.open(url);
    }

    function toTop(){
        // window.scrollTo(0, 0);
        const ele = begin.value;
        const target = getScrollTarget(ele);
        const offset = ele.offsetTop - ele.scrollHeight + 50;
        const duration = 0;
        setVerticalScrollPosition(target, offset, duration)
    }

    function changeOrder(){
		let form = formData;
		form.order = order.value;
		formData = form;
        filterPrice.value = 'all';
		getData();
    }

    function changeItem() {
        page.value = 1;
        totalPage.value = 0;
        nodata.value = false;
        items.value = tmpItems.value;
        if(filterFlag.value != 'ALL'){
            items.value = items.value.filter(function (i){
                return i.flag.toUpperCase()===filterFlag.value.toUpperCase();
            });
        }
        if(filterPrice.value != 'all'){
            items.value = items.value.filter(function (i){
                var price = parseFloat(i.price_thai.replace(/,/g,""));
                if(filterPrice.value=='1000'){
                    return price>1000;
                } else {
                    var arr = filterPrice.value.split("-");
                    return price>=arr[0] && price<=arr[1];
                }
                
            });
            items.value.sort(function(a, b) {
                return parseFloat(a.price_thai.replace(/,/g,"")) - parseFloat(b.price_thai.replace(/,/g,""));
            });
        }
        if(filterDevice.value != 'all'){
            items.value = items.value.filter(function (i){
                return i[filterDevice.value] !== undefined;
            });
        }
        totalPage.value = Math.ceil(items.value.length/perPage);
        if(items.value.length == 0) nodata.value = true;
    }

    async function addLibrary(id){
        loadMy.value = true;
        let form = {
            id:id,
            device:state.device
        }
        await axios.post(hostUrl+'api/addLibrary', JSON.stringify(form))
        .then(function (response) {
            if(!response.data){
                alert("ERROR Please contact admin")
            }else{
                // console.log(response.data)
                if(response.data.data=='login'){
                    $q.notify({
                        message: "Please logout and login again",
                        color: 'red',
                        position: 'top',
                        timeout: 3000
                    })
                } else{
                    items.value.forEach(ele => {
                        if(state.device == 'playstation' && ele.sid == id){ele.own = 'y';}
                        else if(state.device == 'xbox' && ele.xid == id){ele.own = 'y';}
                        else if(state.device == 'nintendo' && ele.nid == id){ele.own = 'y';}
                        else if(state.device == 'steam' && ele.stid == id){ele.own = 'y';}
                        else if(state.device == 'appletv' && ele.aid == id){ele.own = 'y';}
                    });
                }
                
            }
        });
        loadMy.value = false;
    }

    async function delLibrary(id,price){
        loadMy.value = true;
        let form = {
            id:id,
            device:state.device
        }
        await axios.post(hostUrl+'api/delLibrary', JSON.stringify(form))
        .then(function (response) {
            if(!response.data){
                alert("ERROR Please contact admin")
            }else{
                // console.log(response.data)
                if(response.data.data=='login'){
                    $q.notify({
                        message: "Please logout and login again",
                        color: 'red',
                        position: 'top',
                        timeout: 3000
                    })
                } else{
                    if(routeName.value == 'library'){
                        if(state.device == 'playstation') items.value = items.value.filter(item => item.sid !== id)
                        else if(state.device == 'xbox') items.value = items.value.filter(item => item.xid !== id)
                        else if(state.device == 'nintendo') items.value = items.value.filter(item => item.nid !== id)
                        else if(state.device == 'steam') items.value = items.value.filter(item => item.stid !== id)
                        else if(state.device == 'appletv') items.value = items.value.filter(item => item.aid !== id)
                        totalPage.value = Math.ceil(items.value.length/perPage)
                        if(items.value.length == 0) nodata.value = true;
                        total.value = (parseFloat(total.value.replace(/,/g,"")) - parseFloat(price.replace(/,/g,""))).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    } else {
                        items.value.forEach(ele => {
                            if(state.device == 'playstation' && ele.sid == id){ele.own = null;}
                            else if(state.device == 'xbox' && ele.xid == id){ele.own = null;}
                            else if(state.device == 'nintendo' && ele.nid == id){ele.own = null;}
                            else if(state.device == 'steam' && ele.stid == id){ele.own = null;}
                            else if(state.device == 'appletv' && ele.aid == id){ele.own = null;}
                        });
                    }
                }
            }
        });
        loadMy.value = false;
    }

    function diffDate(d) {
        if(d==null) return "";
        var d1 = new Date(d.replace(" ", "T"));
        var d2 = new Date();
        var t1 = d1.getTime();
        var t2 = d2.getTime();
        if(Math.floor((t1-t2)/(24*3600*1000))>0){
            return "in "+Math.floor((t1-t2)/(24*3600*1000))+" Days";
        } else if(Math.floor((t1-t2)/(3600*1000))>0){
            return "in "+Math.floor((t1-t2)/(3600*1000))+" Hour";
        } else if(Math.floor((t1-t2)/(60*1000))>0){
            return "in "+Math.floor((t1-t2)/(60*1000))+" Min";
        }
        else {
            return "";
        }
    }

    function compareDate(obj){
        const diffInMs   = new Date() - new Date(obj)
        const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
        if(diffInDays>60) return false;
        return true
    }

    async function exportExcel(){
        let filename=state.device+'.xlsx';
        var ws = XLSX.utils.json_to_sheet(itmEx);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "My Library");
        XLSX.writeFile(wb,filename);
    }

    function imageHeight(obj) {
        let height = '';
        if(obj.nid && obj.poster && !obj.poster.startsWith('https://fs-prod-cdn.nintendo-europe.com')) height = 'height:100%';
        if(state.device=='steam' && state.view) height = 'height:180px';
        if(state.device == 'home'){
            if(state.view){
                height = 'height:250px';
                // if(Screen.name=='lg') height = 'height:250px';
                // else if(Screen.name=='xl') height = 'height:250px';
                // else if(Screen.name=='md') height = 'height:250px';
                // else if(Screen.name=='sm') height = 'height:250px';
                // else if(Screen.name=='xs') height = 'height:250px';
            } else {
                if(Screen.name=='lg' || Screen.name=='xl' || Screen.name=='md') height = 'height:380px';
                else if(Screen.name=='sm') height = 'height:420px';
                else if(Screen.name=='xs') height = 'height:370px';
            }
        }
        return height;
    }

    function bgColor(obj) {
        let color = '';
        if(state.device == 'home'){
            if(obj.sid) color = 'blue-3';
            else if(obj.xid) color = 'green-3';
            else if(obj.nid) color = 'deep-orange-3';
            else if(obj.stid) color = 'indigo-2';
            else if(obj.aid) color = 'grey-6';
            color = 'bg-'+color;
            color = '';
        }
        return color;
    }

</script>