import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LineLogin from '../views/LineLogin.vue'
import ListView from '../views/ListView.vue'
import ExchangeView from '../views/ExchangeView.vue'
import CloseView from '../views/CloseView.vue'

let routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: LineLogin
  },
  {
    path: '/exchange',
    name: 'exchange.Exchange',
    component: ExchangeView
  },
  {
    path: '/close',
    name: 'close',
    component: CloseView
  },
  {
    path: '/search/:txt',
    name: 'searchParam.home',
    component: ListView
  },
  // {
  //   path: '/playstation',
  //   redirect: '/close'
  // },
  // {
  //   path: '/nintendo',
  //   redirect: '/close'
  // },
  // {
  //   path: '/xbox',
  //   redirect: '/close'
  // },
]

let consoles = ['home','playstation','xbox','nintendo','steam','appletv'];
for(let console of consoles){
  let child = [
    { path: '', name: 'home.'+console, component: HomeView, },
    { path: 'login', name: 'login.'+console, component: LineLogin, },
    { path: 'library', name: 'library.'+console, component: ListView, },
    { path: 'deal', name: 'deal.'+console, component: ListView, },
    { path: 'recent', name: 'recent.'+console, component: ListView, },
    { path: 'newrelease', name: 'newrelease.'+console, component: ListView, },
    { path: 'calendar', name: 'calendar.'+console, component: ListView, },
    { path: 'search', name: 'search.'+console, component: ListView, },
    { path: 'search/:txt', name: 'searchParam.'+console, component: ListView, },
    { path: 'search/:txt', name: 'searchCompany.'+console, component: ListView, },
    { path: 'preorder', name: 'preorder.'+console, component: ListView, },
  ]
  if(console=='playstation'){
    child.push({ path: 'plus', name: 'plus.'+console, component: ListView, });
    child.push({ path: 'essentials', name: 'essentials.'+console, component: ListView, });
    child.push({ path: 'extra', name: 'extra.'+console, component: ListView, });
    child.push({ path: 'deluxe', name: 'deluxe.'+console, component: ListView, });
    child.push({ path: 'subthai', name: 'subthai.'+console, component: ListView, });
  }
  if(console=='xbox'){
    child.push({ path: 'gamepass', name: 'gamepass.'+console, component: ListView, });
    child.push({ path: 'subscription', name: 'subscription.'+console, component: ListView, });
    child.push({ path: 'subthai', name: 'subthai.'+console, component: ListView, });
    child.push({ path: 'pc', name: 'pc.'+console, component: ListView, });
  }
  if(console=='nintendo'){
    child.push({ path: 'subscription', name: 'subscription.'+console, component: ListView, });
    child.push({ path: 'voucher1', name: 'voucher1.'+console, component: ListView, });
    child.push({ path: 'voucher3', name: 'voucher3.'+console, component: ListView, });
  }
  if(console=='steam'){
    child.push({ path: 'subthai', name: 'subthai.'+console, component: ListView, });
    child.push({ path: 'modthai', name: 'modthai.'+console, component: ListView, });
  }
  if(console=='appletv'){
    child.push({ path: 'bundle', name: 'bundle.'+console, component: ListView, });
    child.push({ path: 'lowest', name: 'lowest.'+console, component: ListView, });
    child.push({ path: 'studio/:txt', name: 'studio.'+console, component: ListView, });
  }
  routes.push({ path:'/'+console , children:child });
}

routes.push({ path: '/:pathMatch(.*)*', redirect: '/' });


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
